import Link from '@/components/Link/Link'
import { $isLoggedIn } from '@/stores/account/computed'
import { $customer } from '@/stores/account/stores'
import type { CMSAccountMenu } from '@/types/sanity'
import { useStore } from '@nanostores/react'
import { useMemo, type FC } from 'react'
import './account-items-list.scss'

interface AccountItemsListProps {
  account: CMSAccountMenu
}

const AccountItemsList: FC<AccountItemsListProps> = ({ account }) => {
  const loggedIn = useStore($isLoggedIn)
  const customer = useStore($customer)

  const items = useMemo(() => {
    return (loggedIn && account.loggedInItems) || []
  }, [loggedIn])

  return (
    <>
      <p className="type-h5 account-mobile-header__user">
        Welcome,&nbsp;
        {customer?.firstName}&nbsp;{customer?.lastName.substring(0, 1)}.
      </p>
      {!!items.length && (
        <ul className="account-mobile-header__list">
          {items.map((item) => (
            <li
              key={item.link.url}
              className="account-mobile-header__list-item type-label"
            >
              <Link
                classes={[
                  'account-mobile-header__list-item__link',
                  'type-label',
                ]}
                link={item.link}
              >
                {item.link.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default AccountItemsList
